<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>All Credit Notes</h1>
          <v-form @submit.prevent="getCreditNotes">
            <v-row class="pt-8">
              <v-col class="col">
                <label>Job No</label>
                <v-text-field v-model="jobNumber"></v-text-field>
              </v-col>
              <v-col class="col">
                <label>Sage Ref</label>
                <v-text-field v-model="sageRef"></v-text-field>
              </v-col>
              <v-col class="col">
                <label>Customer</label>
                <v-text-field v-model="customer"></v-text-field>
              </v-col>
              <v-col class="col-3 pt-10">
                <v-btn type="sumbit">Search</v-btn>
                &nbsp;
                <v-btn @click="clearSearch">Clear</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-simple-table
            fixed-header
            height="450px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Customer</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Value</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="note in creditNotes"
                  :key="note.id"
                >
                  <td>{{ note.number }}</td>
                  <td>{{ note.customer }}</td>
                  <td>{{ note.date | tinyDate }}</td>
                  <td>{{ note.total | priceInPounds | currency }}</td>
                  <td class="pr-0">
                    <router-link custom v-slot="{ navigate }" :to="`/invoices/view-credit-note/${note.id}`">
                      <v-btn
                        title="View"
                        x-small
                        @click="navigate"
                        @keypress.enter="navigate"
                        role="link">
                          View
                      </v-btn>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
          ></v-pagination>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
  </template>
  
  <script>
  import axios from '../../axios';
  
  export default {
    name: 'InvoicesCreditNotes',
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        creditNotes: [],
        creditNoteNumber: '',
        customer: '',
        jobNumber: '',
        length: 0,
        page: 1,
        sageRef: '',
        totalVisible: 9,
      };
    },
    watch: {
      page() {
        this.getCreditNotes();
      },
    },
    methods: {
      clearSearch() {
        this.creditNotes = [];
        this.customer = '';
        this.jobNumber = '';
        this.sageRef = '';
        this.setCreditNoteSearchParams();
        this.getCreditNotes();
      },
      setCreditNoteSearchParams() {
        const params = {};
        params.customer = this.customer;
        params.jobNumber = this.jobNumber;
        params.sageRef = this.sageRef;
        this.$store.commit('setCreditNoteSearchParams', params);
      },
      getSearchParams() {
        this.customer = this.$store.state.creditNoteSearchParams.customer;
        this.jobNumber = this.$store.state.creditNoteSearchParams.jobNumber;
        this.sageRef = this.$store.state.creditNoteSearchParams.sageRef;
        this.getCreditNotes();
      },
      getCreditNotes() {
        this.setCreditNoteSearchParams();
        const postData = {
          customer: this.customer,
          jobNumber: this.jobNumber,
          sageRef: this.sageRef,
        };
        axios.post(`/creditNotes/getAll/${this.page}.json?token=${this.token}`, postData)
          .then((response) => {
            this.creditNotes = response.data.creditNotes;
            this.length = response.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getSearchParams();
    },
  };
  </script>
  